/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).attr('target', '_blank').attr('rel', 'noopener');

        // Navigation
        $('.js-toggle-nav').click(function (e) {
          e.preventDefault();

          $('.header').toggleClass('nav-is-visible');
        });

        // Dropdown
        $('.js-toggle-dropdown').click(function (e) {
          e.preventDefault();

          $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
        });

        //Initiate lazy load;
        //new LazyLoad();

        if ($('.js-popup').length && !Cookies.get('popup')) {
          setTimeout(function () {
          $.fancybox.open({
            autoFocus: false,
            src: '.js-popup',
            type: 'inline',
          });

          Cookies.set('popup', 'true', { expires: 1 });
          }, 5000);
         }

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var viewportWidth = $(window).width();
        var maxViewport = 1024;

        // Hero background
        if ($('.hero--static').length) {
          var heroStatic = $('.hero');
          var heroMobileBG = heroStatic.data('mobile');
          var heroDesktopBG = heroStatic.data('desktop');

          if (viewportWidth < maxViewport) {
            if (heroMobileBG) {
              heroStatic.css('background-image', 'url(' + heroMobileBG + ')');
            }
          } else {
            if (heroDesktopBG) {
              heroStatic.css('background-image', 'url(' + heroDesktopBG + ')');
            }
          }
        } else {
          var heroCarousel = $('.hero__carousel__item');

          heroCarousel.each(function () {
            var heroMobileBG = $(this).data('mobile');
            var heroDesktopBG = $(this).data('desktop');

            if (viewportWidth < maxViewport) {
              if (heroMobileBG) {
                $(this).css('background-image', 'url(' + heroMobileBG + ')');
              }
            } else {
              if (heroDesktopBG) {
                $(this).css('background-image', 'url(' + heroDesktopBG + ')');
              }
            }
          });
        }

        if ($('.js-carousel-hero').length) {
          $('.js-carousel-hero').slick({
            accessibility: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            fade: true,
            pauseOnHover: true,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        }

        if ($('.js-carousel-gallery').length) {
          $('.js-carousel-gallery').slick({
            accessibility: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 4000,
            fade: true,
            pauseOnHover: true,
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        }

        if ($('.js-property-gallery').length) {
          $('.js-property-gallery').slick({
            arrows: true,
            asNavFor: '.js-property-gallery-nav',
            dots: false,
            fade: true,
            prevArrow: '<button type="button" class="slick-prev" aria-label="Previous Gallery Item"><i class="fas fa-chevron-right"></i></button>',
            nextArrow: '<button type="button" class="slick-next" aria-label="Next Gallery Item"><i class="fas fa-chevron-left"></i></button>',
            rows: 0,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if ($('.js-property-gallery-nav').length) {
          $('.js-property-gallery-nav').slick({
            arrows: false,
            asNavFor: '.js-property-gallery',
            autoplay: true,
            dots: false,
            focusOnSelect: true,
            rows: 0,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
          });
        }

        /*
        // Detect window scroll
        var jsScrollCheck = $('.js-scroll-check');

        $('window').on('scroll', function() {
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(this).height();

        // Add class to body
        if (viewportTop > 1) {
        $('body').addClass('is-scrolling');
      } else {
      $('body').removeClass('is-scrolling');
    }

    // Detect if element is visible in viewport
    jsScrollCheck.each(function () {
    var jsScrollCheckTop = $(this).offset().top;

    if (jsScrollCheckTop < viewportBottom) {
    $(this).addClass('is-visible');
  }
});
});
*/
}
},
// Home page
'home': {
  init: function () {
    // JavaScript to be fired on the home page

  },
  finalize: function () {
    // JavaScript to be fired on the home page, after the init JS
  }
}
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function (func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function () {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
